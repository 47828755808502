import { Counter } from './components/Counter';
import { Home } from './pages/Home';
import { Upgrade } from './pages/Upgrade';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: '/upgrade',
    element: <Upgrade />,
  },
  {
    path: '/counter',
    element: <Counter />,
  },
];

export default AppRoutes;
