import {
  Components,
  PaletteOptions,
  SimplePaletteColorOptions,
} from '@mui/material';

const GetThemeComponents = (themePalette: PaletteOptions): Components => {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          '&.MuiButton-sizeNavlarge': {
            color: themePalette.text?.secondary,
            height: '3.5rem',
            borderRadius: '0',
            '& svg': { fontSize: '20px' },
          },
          '&.MuiButton-sizeNavlargeactive': {
            textDecoration: 'none',
            boxShadow: 'none',
            height: '3.5rem',
            borderRadius: '0',
            '& svg': { fontSize: '20px' },
          },
          '&[data-hovered-variant="true"],&[data-hovered-variant="true"]:hover':
            {
              borderRadius: `${4 * 8}px`,
            },
          '&.MuiButton-textInherit[data-hovered-variant="true"]': {
            backgroundColor: themePalette.grey?.[100],
          },
          '&.MuiButton-outlined': {
            '&.MuiButton-outlinedInherit': {
              backgroundColor: themePalette.grey?.[50],
              border: '1px solid rgba(0, 0, 0, 0.12)',
            },
            '&.MuiButton-outlinedSizeMedium': {
              padding: '5px 15px',
            },
            '&.MuiButton-outlinedPrimary.active': {
              backgroundColor: 'rgba(5, 114, 230, 0.04) !important',
            },
            '&.MuiButton-outlinedSuccess.active': {
              backgroundColor: 'rgba(46, 125, 50, 0.04) !important',
            },
          },
          '&.MuiButton-sizeSmall': {
            fontSize: '13px',
            lineHeight: '22px',
            letterSpacing: '0.46px',
          },
          '&.MuiButton-sizeMedium': {
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.4px',
          },
          '&.MuiButton-sizeLarge': {
            fontSize: '15px',
            lineHeight: '26px',
            letterSpacing: '0.46px',
          },
          '&.MuiButton-contained': {
            borderRadius: '0.5rem',
            boxShadow: 'none',
          },
          '& .MuiChip-root': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiChip-root': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-sizeMedium': {
            height: '2rem',
            width: '2rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&[data-hovered-variant="true"],&[data-hovered-variant="true"]:hover':
            {
              borderRadius: `${4 * 8}px`,
            },
          '&.MuiOutlinedInput-root[data-hovered-variant="true"]': {
            backgroundColor: themePalette.grey?.[100],
            '&:hover': { backgroundColor: themePalette.grey?.[100] },
            '& fieldset': {
              borderColor: 'transparent !important',
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&[data-hovered-variant="true"]': {
            borderRadius: `${4 * 8}px`,
            fontWeight: 400,
          },
          '&[data-hovered-variant="true"]:hover': {
            backgroundColor: themePalette.grey?.[100],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': { height: '3px' },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            padding: '14px 16px',
          },
          '& .MuiDivider-root': {
            margin: '0 !important',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: themePalette.grey?.[200],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardWarning': {
            backgroundColor: '#FDF0E6',
            '& .MuiAlert-icon': {
              color: (themePalette.warning as SimplePaletteColorOptions)?.main,
            },
            '& .MuiAlert-message': {
              color: '#5F2B01',
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: '400',
          lineHeight: '18px',
          letterSpacing: '0.16px',
          '& .MuiChip-labelSmall': {
            paddingLeft: '0.5625rem',
            paddingRight: '0.5625rem',
          },
          '& .MuiChip-labelMedium': {
            paddingLeft: '0.9375rem',
            paddingRight: '0.9375rem',
          },
          '&.MuiChip-filledInfolight': {
            color: (themePalette.primary as SimplePaletteColorOptions)?.main,
          },
          '&.MuiChip-filled': {
            border: '1px solid transparent',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${themePalette.grey?.[100]}!important`,
          },
          '&:hover': {
            backgroundColor: `${themePalette.grey?.[100]}!important`,
          },
        },
      },
    },
  };
};

export default GetThemeComponents;
