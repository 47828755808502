import { Errors } from '@/types/ApiServicesTypes';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { SendOtp } from '../ApiServices/OtpApiServices';
import { SendOtpResult, SendOtpParams } from './types';

export default function useSendOtp() {
  const queryKey = ['SendOtp'];

  const mutation = useMutation<
    SendOtpResult,
    Errors,
    Pick<SendOtpParams, 'phone'>
  >(queryKey, values =>
    SendOtp(values)
      .then(res => {
        return res;
      })
      .catch(err => {
        toast.error(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    toast.error(mutation.error.message);
  }

  return mutation;
}
