import { makePostRestRequest } from 'src/services/apiServices';
import {
  SendOtpParams,
  SendOtpResult,
  VerifyOtpParams,
  VerifyOtpResult,
} from '../Otp/types';

export async function SendOtp(params: SendOtpParams): Promise<SendOtpResult> {
  const path = `otp/send`;
  return await makePostRestRequest<SendOtpParams, SendOtpResult>(
    path,
    params,
    'application/json',
  );
}

export async function VerifyOtp(
  params: VerifyOtpParams,
): Promise<VerifyOtpResult> {
  const path = `otp/verify`;
  return await makePostRestRequest<VerifyOtpParams, VerifyOtpResult>(
    path,
    params,
    'application/json',
  );
}
