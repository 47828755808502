import { useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Button,
  Box,
  Grid,
  Link,
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  OutlinedInput,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { useGetMediaQueryUp } from 'src/hooks/useGetMediaQuery';
import { DEFAULT_COUNTRY_CODE } from 'src/constants/common';
import MuiPhoneNumber from 'material-ui-phone-number-2';
import useSignup from 'src/services/Auth/useSignup';
import useSendOtp from 'src/services/Otp/useSendOtp';
import useVerifyOtp from 'src/services/Otp/useVerifyOtp';
import { LoadingButton } from '@mui/lab';
import parsePhoneNumber from 'libphonenumber-js';
import { IndustryTypes } from 'src/staticData/IndustryTypes';
import { BusinessSizes } from 'src/staticData/BusinessSizes';

let interval: NodeJS.Timer;

export const Home = () => {
  const [isOtp, setIsOtp] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [countdown, setCountDown] = useState(5);

  const isLgUp = useGetMediaQueryUp('lg');

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const mutationSignup = useSignup();
  const mutationSendOtp = useSendOtp();
  const mutationVerifyOtp = useVerifyOtp();

  const handleClickSuccess = () => {
    let cnt = countdown;
    interval = setInterval(() => {
      if (cnt <= 0 && interval) {
        clearInterval(interval);
        window.location.href = 'https://dashboard.botbaba.io/login';
      } else {
        setCountDown(cnt);
      }
      cnt--;
    }, 1000);
  };

  const handleSendOtp = (
    phone: string,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    mutationSendOtp.mutate(
      { phone },
      {
        onSuccess: res => {
          if (res.success && res.data) {
            setIsOtp(true);
            setFieldError('phone', '');
          } else {
            setIsOtpVerified(false);
            setFieldError('phone', res.error);
          }
        },
      },
    );
  };

  const handleVerifyOtp = (
    phone: string,
    otp: string,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    mutationVerifyOtp.mutate(
      { phone, otp },
      {
        onSuccess: res => {
          if (res.success && res.data && res.data.verified) {
            setIsOtpVerified(true);
            setFieldError('otp', '');
          } else {
            setIsOtpVerified(false);
            setFieldError('otp', 'Otp is invalid');
          }
        },
      },
    );
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container>
        <Grid
          item
          xs={0}
          lg={7}
          sx={{
            backgroundImage: 'url("/resources/Login 886x765.png")',
            backgroundPosition: 'center',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat%',
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sx={{
            backgroundColor: 'white',
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Box sx={{ my: 3, width: '100%' }} className="p-main-spacing">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mb: 1.5 }}
            >
              <Box
                component="img"
                src="/logo.png"
                alt="logo"
                sx={{ height: '3rem' }}
              />
            </Box>
            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight={500}
              >
                Sign up your account
              </Typography>
            </Box>
            <Box>
              <Formik
                initialValues={{
                  name: '',
                  organizationName: '',
                  industry: '',
                  businessSize: '',
                  countryCode: DEFAULT_COUNTRY_CODE,
                  phone: '',
                  mobile: DEFAULT_COUNTRY_CODE,
                  email: '',
                  password: '',
                  otp: '',
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Full name is required'),
                  organizationName: Yup.string().required(
                    'Organization name is required',
                  ),
                  industry: Yup.string().required('Industry is required'),
                  businessSize: Yup.string().required(
                    'Business Size is required',
                  ),
                  countryCode: Yup.string()
                    .min(1, 'Country code must be at least one digit.')
                    .max(3, 'Country code must be less than three digit.')
                    .required('Country code is required.'),
                  phone: Yup.string().required('WhatsApp nubmer is required.'),
                  mobile: Yup.string()
                    .phone(
                      undefined,
                      undefined,
                      'Please enter a valid WhatsApp number.',
                    )
                    .required('Phone nubmer is required.'),
                  password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password must be at least 8 characters'),
                  email: Yup.string()
                    .required('Email is required')
                    .email('Please enter valid email address'),
                })}
                onSubmit={(values, actions) => {
                  mutationSignup.mutate(values, {
                    onSuccess: res => {
                      if (!res.success && res.fieldErrors) {
                        Object.entries(res.fieldErrors).forEach(x => {
                          if (x[0].includes('email'))
                            actions.setErrors({ email: x[1].join('\n\r') });
                          else actions.setErrors({ [x[0]]: x[1].join('\n\r') });
                        });
                      } else if (res.success) {
                        handleClickSuccess();
                      }
                      actions.setSubmitting(false);
                    },
                  });
                }}
              >
                {({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  setFieldTouched,
                  setFieldError,
                }) => (
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ width: '100%', marginX: { md: 'auto' } }}
                  >
                    {mutationSignup.data && mutationSignup.data.error && (
                      <Alert sx={{ mt: 1 }} severity="error">
                        {mutationSignup.data.error}
                      </Alert>
                    )}
                    {mutationSignup.data && mutationSignup.data.success && (
                      <>
                        <Alert sx={{ mt: 1 }} severity="success">
                          We have sent you an email on {values.email}. Please
                          click the link in the email to verify your email and
                          login.
                        </Alert>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 1, textAlign: 'center' }}
                        >
                          We will redirecting in {countdown} seconds
                        </Typography>
                      </>
                    )}
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      label="Full Name*"
                      onChange={handleChange}
                      name="name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error>{errors.name}</FormHelperText>
                    )}
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      label="Organization Name*"
                      onChange={handleChange}
                      name="organizationName"
                      value={values.organizationName}
                      error={Boolean(
                        touched.organizationName && errors.organizationName,
                      )}
                    />
                    {touched.organizationName && errors.organizationName && (
                      <FormHelperText error>
                        {errors.organizationName}
                      </FormHelperText>
                    )}
                    <FormControl
                      fullWidth
                      sx={{ mt: 2 }}
                      error={Boolean(touched.industry && errors.industry)}
                    >
                      <InputLabel htmlFor="outlined-adornment-industry-login">
                        Industry
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        name="industry"
                        value={values.industry}
                        label="Industry"
                      >
                        {IndustryTypes &&
                          IndustryTypes.map(IndustryType => (
                            <MenuItem
                              value={IndustryType.value}
                              key={IndustryType.value}
                            >
                              {IndustryType.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.industry && errors.industry && (
                        <FormHelperText error sx={{ mx: 0 }}>
                          {errors.industry}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ mt: 2 }}
                      error={Boolean(
                        touched.businessSize && errors.businessSize,
                      )}
                    >
                      <InputLabel htmlFor="outlined-adornment-businessSize-login">
                        Business Size
                      </InputLabel>
                      <Select
                        onChange={handleChange}
                        name="businessSize"
                        value={values.businessSize}
                        label="Business Size"
                      >
                        {BusinessSizes &&
                          BusinessSizes.map(businessSize => (
                            <MenuItem
                              value={businessSize.value}
                              key={businessSize.value}
                            >
                              {businessSize.label}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.businessSize && errors.businessSize && (
                        <FormHelperText error sx={{ mx: 0 }}>
                          {errors.businessSize}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      label="Email*"
                      type="email"
                      onChange={handleChange}
                      name="email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error>{errors.email}</FormHelperText>
                    )}
                    <MuiPhoneNumber
                      sx={{
                        mt: 2,
                        '& .MuiPhoneNumber-flagButton': { width: '30px' },
                        '& svg': { height: '1em' },
                      }}
                      fullWidth
                      label="WhatsApp Number*"
                      name="phone"
                      defaultCountry={'in'}
                      disableAreaCodes={true}
                      disableCountryCode={false}
                      autoFormat={true}
                      disabled={isOtpVerified}
                      error={Boolean(
                        touched.phone && (errors.phone || errors.mobile),
                      )}
                      onBlur={handleBlur}
                      onChange={(
                        formattedNumber:
                          | string
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                      ) => {
                        const phoneData = parsePhoneNumber(
                          formattedNumber as string,
                        );

                        if (phoneData) {
                          setFieldValue(
                            'phone',
                            phoneData.number.replace(
                              '+' + phoneData.countryCallingCode,
                              '',
                            ),
                            false,
                          );
                          setFieldValue(
                            'countryCode',
                            phoneData.countryCallingCode as string,
                            false,
                          );
                          setFieldValue(
                            'mobile',
                            phoneData.number as string,
                            false,
                          );
                        }
                      }}
                      variant="outlined"
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error>{errors.phone}</FormHelperText>
                    )}
                    {touched.phone && !errors.phone && errors.mobile && (
                      <FormHelperText error>{errors.mobile}</FormHelperText>
                    )}
                    <Box sx={{ textAlign: 'right', mt: 0.5 }}>
                      <Link
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                          gap: 1,
                        }}
                        onClick={() => {
                          if (isOtpVerified) return;
                          if (!touched.phone) setFieldTouched('phone');
                          else if (
                            !errors.countryCode &&
                            !errors.mobile &&
                            !errors.phone
                          ) {
                            handleSendOtp(
                              values.countryCode + values.phone,
                              setFieldError,
                            );
                          }
                        }}
                      >
                        Send Otp{' '}
                        {mutationSendOtp.isLoading && (
                          <CircularProgress size={'1rem'} />
                        )}
                      </Link>
                    </Box>
                    {isOtp && (
                      <>
                        <Box>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.otp && errors.otp)}
                            variant="outlined"
                            sx={{ mt: 2 }}
                          >
                            <InputLabel htmlFor="outlined-adornment-otp-login">
                              Otp
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-otp-login"
                              name="otp"
                              value={values.otp}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={isOtpVerified}
                              endAdornment={
                                <InputAdornment position="end">
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleVerifyOtp(
                                        values.countryCode + values.phone,
                                        values.otp,
                                        setFieldError,
                                      )
                                    }
                                    disabled={
                                      !values.otp ||
                                      values.otp.length < 6 ||
                                      isOtpVerified
                                    }
                                  >
                                    Verify
                                  </Button>
                                </InputAdornment>
                              }
                              label="Otp*"
                              inputProps={{}}
                            />
                            {touched.otp && errors.otp && (
                              <FormHelperText error sx={{ mx: 0 }}>
                                {errors.otp}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </>
                    )}
                    <FormControl
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      variant="outlined"
                      sx={{ mt: 2, mb: 3 }}
                    >
                      <InputLabel htmlFor="outlined-adornment-password-login">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password-login"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password*"
                        inputProps={{}}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error sx={{ mx: 0 }}>
                          {errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <LoadingButton
                      size={isLgUp ? 'large' : 'large'}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                      loading={mutationSignup.isLoading}
                      disabled={!isOtpVerified}
                      type="submit"
                    >
                      Sign Up
                    </LoadingButton>

                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                      Already have an account?{' '}
                      <Link href="https://dashboard.botbaba.io/login">
                        Sign In
                      </Link>
                    </Box>
                  </Box>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
