export const IndustryTypes = [
  { label: 'Digital Marketing Agency', value: 'Digital Marketing Agency' },
  {
    label: 'Consultant (Management, Finance, etc)',
    value: 'Consultant',
  },
  { label: 'E-Commerce', value: 'E-Commerce' },
  { label: 'SaaS', value: 'SaaS' },
  { label: 'Education', value: 'Education' },
  { label: 'Government', value: 'Government' },
  { label: 'Healthcare', value: 'Healthcare' },
  { label: 'Arts & Entertainment', value: 'Arts & Entertainment' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Technology', value: 'Technology' },
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Others', value: 'Others' },
];
