import { ReactNode } from 'react';

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      {/* <NavMenu /> */}
      {/* <Container> */}
      {children}
      {/* </Container> */}
    </div>
  );
};
