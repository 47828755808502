import {
  Box,
  Grid,
  useTheme,
  Container,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import PlanCard from 'src/components/UpgradePage/PlanCard';
import { Plan } from '@/types/Plan';
import React from 'react';

const plans: Plan[] = [
  // {
  //   id: 'free',
  //   name: 'Free',
  //   price: 0,
  //   bots: 1,
  //   bot_users: 200,
  //   members: 0,
  //   is_yearly: false,
  // },
  {
    id: 'business_plan',
    name: 'Business Plan',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: false,
  },
  {
    id: 'business_plan',
    name: 'Business Plan',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: false,
  },
  {
    id: 'business_plan',
    name: 'Business Plan',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: false,
  },
  {
    id: 'business_plan_yearly',
    name: 'Business Plan Yearly',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: true,
  },
  {
    id: 'business_plan_yearly',
    name: 'Business Plan Yearly',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: true,
  },
  {
    id: 'business_plan_yearly',
    name: 'Business Plan Yearly',
    price: 10,
    bots: 1,
    bot_users: 1000,
    members: 5,
    is_yearly: true,
  },
];

export const Upgrade = () => {
  const theme = useTheme();
  const [type, setType] = React.useState('monthly');

  const handleChangeType = (event: React.MouseEvent<HTMLElement>) => {
    setType((event.target as HTMLInputElement).value);
  };

  const isYearly = type == 'yearly';

  return (
    <Box sx={{ height: '100%' }}>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 4 }}
        >
          <Box
            component="img"
            src="/logo.png"
            alt="logo"
            sx={{ height: '2.5rem' }}
          />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={handleChangeType}
            sx={{
              backgroundColor: 'white',
              borderRadius: 8,
              '& .MuiButtonBase-root ': {
                border: 0,
                borderRadius: '1.5rem !important',
                m: 1,
              },
            }}
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid
          container
          justifyContent={'center'}
          sx={{ my: 2, [theme.breakpoints.down('md')]: { my: 0 } }}
          spacing={3}
        >
          {plans
            .filter(x => x.is_yearly === isYearly)
            .map((plan, i) => (
              <Grid item xs={12} lg={4} key={i}>
                <PlanCard plan={plan} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};
