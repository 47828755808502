// Coockie Constants
export const SESSION_ID = 'btbb_sessionId';
export const USER_DETAILS = 'btbb_userDetails';
export const USER_NAME = 'btbb_userName';

// Api Constants
export const API_FETCH_TIMEOUT = 10 * 1000; //10 seconds
export const DEFAULT_COUNTRY_CODE = '91';
export const PUBLISHER_QUERY = 'Publisher_Query';
// Common Constants
export const MAX_ITEM_SHOW_MORE = 5;

// Regenx
export const URL_VALIDATION_REGEX =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
