import { Breakpoint, Theme, useMediaQuery } from '@mui/material';

const useGetMediaQueryUp = (key: Breakpoint) => {
  const isSizeUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(key));

  return isSizeUp;
};

const useGetMediaQueryDown = (key: Breakpoint) => {
  const isSizeDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(key),
  );

  return isSizeDown;
};

const useGetMediaQueryOnly = (key: Breakpoint) => {
  const isSizeDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only(key),
  );

  return isSizeDown;
};

export { useGetMediaQueryUp, useGetMediaQueryDown, useGetMediaQueryOnly };
