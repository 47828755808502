import { MutationKey, QueryClient, QueryKey } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { BaseApiResponse } from '@/types/ApiServicesTypes';
import { toast } from 'react-toastify';

// const DEFAULT_QUERY_STALE_TIME = 5 * 60 * 1000; // Set 1 second for temporary reason
// const DEFAULT_QUERY_CACHE_TIME = 24 * 60 * 60 * 1000;
const DEFAULT_QUERY_STALE_TIME = 1 * 1000; // Set 1 second for temporary reason
const DEFAULT_QUERY_CACHE_TIME = 24 * 60 * 60 * 1000;

export const queryNoCachList = [];

type InnerKey = {
  key: string | ((mutationKey: MutationKey) => string | unknown[]);
};

const avoidErrorKeys = ['Signup'];

export const queryInvalidationMap: {
  [key: string]: InnerKey[];
} = {
  EditProfile: [{ key: 'GetProfile' }],
  ReadNotifications: [{ key: 'GetNotifications' }],
  ReadAllNotifications: [{ key: 'GetNotifications' }],
  AddQCaseToWatchlist: [
    { key: 'GetWatchList' },
    { key: 'GetCaseById' },
    { key: 'GetHomeCasesManagers' },
  ],
  RemoveQCaseFromWatchlist: [
    { key: 'GetWatchList' },
    { key: 'GetCaseById' },
    { key: 'GetHomeCasesManagers' },
  ],
  PostInvestment: [
    {
      key: (mutationKey: MutationKey) => ['GetCaseById', mutationKey[1]],
    },
    {
      key: (mutationKey: MutationKey) => ['GetCaseChart', mutationKey[1]],
    },
    { key: 'GetInvestments' },
  ],
  GetCaseDetails: [{ key: 'GetHomeCaseManagers' }],
  PostProfile: [{ key: 'GetProfile' }],
  SavePublisherCase: [
    { key: 'GetPublisherCases' },
    {
      key: (mutationKey: MutationKey) => {
        if (Array.isArray(mutationKey))
          return ['GetPublisherCaseById', mutationKey[1]];
        return ['GetPublisherCaseById'];
      },
    },
  ],
  DeletePublisherCase: [{ key: 'GetPublisherCases' }],
};

export const queryCacheClear = [
  // 'Authenticate',
  'AuthenticateWithGoogle',
  'AuthenticateDefault',
];

export const invalidateQueryCache = (mutationKey: MutationKey) => {
  const key =
    typeof mutationKey === 'string' ? mutationKey : (mutationKey[0] as string);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const queriesKeyForInvalidation = queryInvalidationMap[key];
  if (queriesKeyForInvalidation) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    queriesKeyForInvalidation.map(queryAction => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const key = queryAction.key;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const queryKey: QueryKey =
        typeof key === 'function'
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            key(mutationKey)
          : key;

      void queryClient.invalidateQueries(queryKey, {
        refetchActive: true,
        refetchInactive: false,
      });
    });
  }
};

export const checkForClearCache = (key: string) => {
  if (queryCacheClear.indexOf(key) > -1) {
    clearQueryCache();
  }
};

export const clearQueryCache = () => {
  const queryCache = queryClient.getQueryCache();
  queryCache.clear();
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      cacheTime: DEFAULT_QUERY_CACHE_TIME,
      // suspense: true,
      onSuccess: function (res) {
        const resData: BaseApiResponse = res as BaseApiResponse;
        if (resData.mutationKey && resData.mutationKey.length > 0) {
          if (
            resData.error &&
            !avoidErrorKeys.includes(resData.mutationKey[0] as string)
          ) {
            toast.error(resData.error);
          }
        }
      },
    },
    mutations: {
      onSuccess: function () {
        const mutationKey = this.mutationKey;
        if (mutationKey) {
          const key =
            typeof mutationKey === 'string'
              ? mutationKey
              : (mutationKey[0] as string);

          invalidateQueryCache(mutationKey);
          checkForClearCache(key);
        }
      },
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage:
    typeof window !== 'undefined' ? window.localStorage : ({} as Storage),
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
}).catch(error => {
  //TODO: Send persist storage did not work error.
  // eslint-disable-next-line no-console
  console.error(error);
});
