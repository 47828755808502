import React from 'react';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import { UserInfo2 } from '@/types/User';
import { clearQueryCache } from '../QueryClient';
import { SESSION_ID, USER_DETAILS, USER_NAME } from 'src/constants/common';
// import { SESSION_ID, USER_NAME, USER_DETAILS } from '@/constants/common';

// export const SESSION_ID = 'btbb_sessionId';
// export const USER_DETAILS = 'btbb_userDetails';
// export const USER_NAME = 'btbb_userName';

export const isUserLoggedIn = () => {
  return Boolean(
    getCookie(SESSION_ID) && getCookie(USER_DETAILS),
    // getCookie(SESSION_ID) && getCookie(USER_NAME) && getCookie(USER_ID),
  );
};

const clearCookies = () => {
  // localStorage.removeItem(SESSION_ID);
  removeCookie(SESSION_ID);
  removeCookie(USER_DETAILS);
  removeCookie(USER_NAME);
};

export function cleanUpAuth() {
  clearCookies();
  clearQueryCache();
}

export const isSignedIn = () => {
  const userDetails = getUserInfo();
  const isAuth = Boolean(
    userDetails &&
      userDetails.expireTime &&
      new Date(userDetails.expireTime) > new Date(),
  );
  if (!isAuth) {
    clearCookies();
    // clearQueryCache();
  }
  return isAuth;
};

export const getUserInfo = () => {
  const userDetails = getCookie(USER_DETAILS) as string;
  if (!userDetails) return null;
  return JSON.parse(userDetails) as UserInfo2;
};

export const setUserInfo = (userInfo: UserInfo2) => {
  clearQueryCache();

  removeCookie(USER_DETAILS);
  removeCookie(SESSION_ID);

  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 1);

  userInfo.expireTime = expireDate;
  setCookie(USER_DETAILS, JSON.stringify(userInfo));
  setCookie(SESSION_ID, userInfo.sessionId);
};

export const useDoSingIn = () => {
  const doSignIn = React.useCallback(
    (userDetails?: UserInfo2, ref?: string) => {
      if (userDetails && userDetails.sessionId) {
        setUserInfo(userDetails);
      }
      // void Router.push(ref ? ref : '/');
    },
    [],
  );

  const doCleanUp = React.useCallback(() => {
    cleanUpAuth();
  }, []);

  return { doSignIn, doCleanUp };
};

export const useDoSingOut = () => {
  const doSignOut = React.useCallback(() => {
    cleanUpAuth();
    // void Router.push({ pathname: '/' });
  }, []);

  return { doSignOut };
};

export const handleUnauthorized = (ref?: string) => {
  cleanUpAuth();
  // window.open(ref ? ref : '/', '_self');
  window.location.assign(ref ? ref : '/');
};
