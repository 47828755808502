import { ApiError, Errors } from '@/types/ApiServicesTypes';
import { getCookie } from 'typescript-cookie';
// import { API_FETCH_TIMEOUT, SESSION_ID } from '@/constants/common';
import { handleUnauthorized } from './Auth';
import { toast } from 'react-toastify';
import { SESSION_ID, API_FETCH_TIMEOUT } from 'src/constants/common';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;

// eslint-disable-next-line
function BuildRequest(params: any) {
  return JSON.stringify({
    jsonrpc: '2.0',
    method: 'call',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    params: params, // payload
  });
}

function timeout(ms: number, promise: Promise<Response>) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'));
    }, ms);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    promise
      .then(value => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch(reason => {
        clearTimeout(timer);
        reject(reason);
      });
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function HandleErrors(response: any): Errors {
  if (response.error.code === 401) {
    handleUnauthorized();
  }
  return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function HandleAPIErrors(response: any): Errors {
  const error: string = (response.statusText as string) || '';
  const statusCode: number = response.status as number;
  const objError = {
    title: '    ',
    message: '',
    fullTrace: error,
  };

  if (statusCode === 401) {
    handleUnauthorized();
    objError.title = '';
    objError.message = '';
  } else {
    if (!navigator.onLine) {
      objError.title = '';
      objError.message = '';
    } else {
      objError.title = 'Unknown error. Please try again.';
      objError.message = 'Unknown error. Please try again.';
    }
  }
  return objError;
}

type FetchOptionType = {
  method: string;
  headers: {
    'Content-Type': string;
    'session-id'?: string;
  };
  body?: string | null;
};

export async function MakeApiRequest<P, R>(
  path: string,
  params: P = {} as P,
  method: string,
  content_type: string,
): Promise<{ result?: R; error?: Errors }> {
  try {
    try {
      let session_id = getCookie(SESSION_ID) as string;
      if (!session_id) session_id = '';

      const headers = {
        'Content-Type': content_type,
        'session-id': session_id,
      };
      const fetchOptions: FetchOptionType = {
        method: method,
        headers: headers,
      };
      if (
        params &&
        (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
      )
        fetchOptions.body = JSON.stringify(params);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await timeout(
        API_FETCH_TIMEOUT,
        fetch(`${API_BASE_URL}/${path}`, fetchOptions),
      );

      if (!response.ok) {
        const objErrorMessage = HandleAPIErrors(response);
        return { error: objErrorMessage };
      }
      // eslint-disable-next-line
      const rresponse = await response.json();

      // if (rresponse.result && rresponse.result.session_id) {
      //   localStorage.setItem(SESSION_ID, rresponse.result.session_id as string);
      // }

      if (!rresponse.error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { result: rresponse.result };
      } else {
        return { error: HandleErrors(rresponse) };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if ((err && err.message === 'TIMEOUT') || !navigator.onLine)
        return { error: { title: '', message: '' } };
      return {
        error: {
          title: 'Unknown error. Please try again.',
          message: 'Unknown error. Please try again.',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        title: 'Api request error',
        message: 'Api request error',
        error: error,
      },
    };
  }
}

export async function RestRequest<P, R>(
  path: string,
  params: P = {} as P,
  method: string,
  contentType: string,
): Promise<{ result?: R; error?: Errors }> {
  try {
    try {
      let session_id = getCookie(SESSION_ID) as string;
      if (!session_id) session_id = '';
      // if (!session_id) {
      //   session_id = '';
      //   handleUnauthorized();
      // }
      // const session_id = FIX_SESSION_ID;
      const headers = {
        'Content-Type': contentType,
        'session-id': session_id,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let response: any;
      try {
        const baseUrl = API_BASE_URL;
        if (contentType === 'text/html') {
          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${baseUrl}/${path}`, {
              method: method,
              headers: headers,
            }),
          );
        } else {
          const fetchOptions: FetchOptionType = {
            method: method,
            headers: headers,
          };
          if (
            params &&
            (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
          ) {
            fetchOptions.body = JSON.stringify(params);
          }

          response = await timeout(
            API_FETCH_TIMEOUT,
            fetch(`${baseUrl}/${path}`, fetchOptions),
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if ((err && err.message === 'TIMEOUT') || !navigator.onLine)
          return { error: { title: '', message: '' } };
        return {
          error: {
            // title: 'Network unreachable. Check your connection.',
            // message: 'Network unreachable. Check your connection.',
            title: 'Unknown error. Please try again.',
            message: 'Unknown error. Please try again.',
          },
        };
      }

      if (!response.ok) {
        const objErrorMessage = HandleAPIErrors(response);
        return { error: objErrorMessage };
      }
      if (contentType === 'text/html') {
        // eslint-disable-next-line
        response = await response.json();
      } else {
        // eslint-disable-next-line
        response = await response.json();
      }

      if (response.total_pages) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const objResult = response.result;
        // eslint-disable-next-line
        objResult.map((val: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          val['total_pages'] = response.total_pages;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          val['count'] = response.count;
          return val;
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return { result: objResult };
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { result: response.result ? response.result : response };
    } catch (err) {
      return {
        error: {
          title: !navigator.onLine ? 'Unknown error. Please try again.' : '',
          message: !navigator.onLine ? 'Unknown error. Please try again.' : '',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        title: 'Api request error',
        message: 'Api request error',
        error: error,
      },
    };
  }
}

export async function makeRestRequest<P, R>(
  path: string,
  params: P,
  method = 'GET',
  contentType = 'application/json',
) {
  const response = await RestRequest<P, R>(path, params, method, contentType);

  if (response.result) {
    return response.result;
  } else {
    if (!navigator.onLine) {
      throw new Error('');
    } else if (response.error) {
      throw new Error(response.error.message);
    } else {
      throw new Error('Unknown error. Please try again.');
    }
  }
}

export async function makePostRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'POST', contentType);
}

export async function makePutRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'PUT', contentType);
}

export async function makeGetRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'GET', contentType);
}

export async function makeDeleteRestRequest<P, R>(
  path: string,
  params: P,
  contentType?: string,
) {
  return await makeRestRequest<P, R>(path, params, 'DELETE', contentType);
}

export const alertApiErrors = (errors: ApiError[]) => {
  if (errors && errors.length) {
    errors.forEach(err => {
      const msg = err.message + (err.description ? ' ' + err.description : '');
      if (err.type === 'error') toast.error(msg);
      if (err.type === 'info') toast.info(msg);
      if (err.type === 'warn') toast.warning(msg);
      if (err.type === 'success') toast.success(msg);
    });
  }
};

// ==================================================
// ====================SSR===========================
// ==================================================

type FetchOptionType2 = {
  method: string;
  headers: {
    'content-type': string;
    'session-id'?: string;
  };
  body?: string | null;
};

export async function makeRestRequestSsr<P, R>(
  baseUrl: string,
  path: string,
  params: P,
  method = 'GET',
  contentType = 'application/json',
  session_id = '',
): Promise<{ result?: R; error?: R }> {
  baseUrl = 'https://dev-api.qualitycase.tech' + API_BASE_URL;
  const commonError = {
    success: false,
    errors: [
      {
        type: 'error',
        message: 'Unknown error. Please try again.',
      },
    ],
  };
  try {
    // const session_id = getCookie(SESSION_ID) as string;

    const headers = {
      'content-type': contentType,
      ...(session_id && { 'session-id': session_id }),
    };

    const fetchOptions: FetchOptionType2 = {
      method: method,
      headers: headers,
    };
    if (
      params &&
      (method.toLowerCase() === 'post' || method.toLowerCase() === 'put')
    ) {
      fetchOptions.body = JSON.stringify(params);
    }

    const res = await fetch(`${baseUrl}/${path}`, fetchOptions);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await res.json();

    return { result: response as R };
  } catch (err) {
    return { error: commonError as unknown as R };
  }
}
