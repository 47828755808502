import { Plan } from '@/types/Plan';
import { Box, Typography, Divider } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export type PlanBoxProps = {
  plan: Plan;
};

const PlanBox = ({ plan }: PlanBoxProps) => {
  return (
    <Box
      sx={{
        borderRadius: 4,
        backgroundColor: 'white',
        px: { xs: 2, md: 3 },
        py: { xs: 2, md: 3 },
      }}
    >
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        {plan.name}
      </Typography>
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="center"
        sx={{ mb: 2 }}
      >
        <Typography variant="h4" fontWeight={500}>
          ${plan.price}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          /{plan.is_yearly ? 'year' : 'month'}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ mt: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ py: 1, color: 'text.secondary' }}
        >
          <ArrowForwardIosIcon fontSize="small" />
          <Typography variant="body1" color="text.secondary" sx={{ ml: 2 }}>
            {plan.bots} Bot{plan.bots > 1 ? 's' : ''}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ py: 1, color: 'text.secondary' }}
        >
          <ArrowForwardIosIcon fontSize="small" />
          <Typography variant="body1" color="text.secondary" sx={{ ml: 2 }}>
            {plan.bot_users} Bot users
          </Typography>
        </Box>
        {plan.members > 0 && (
          <Box
            display="flex"
            alignItems="center"
            sx={{ py: 1, color: 'text.secondary' }}
          >
            <ArrowForwardIosIcon fontSize="small" />
            <Typography variant="body1" color="text.secondary" sx={{ ml: 2 }}>
              {plan.members} Members included
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PlanBox;
