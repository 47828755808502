import { Errors } from '@/types/ApiServicesTypes';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { VerifyOtp } from '../ApiServices/OtpApiServices';
import { VerifyOtpResult, VerifyOtpParams } from './types';

export default function useVerifyOtp() {
  const queryKey = ['VerifyOtp'];

  const mutation = useMutation<
    VerifyOtpResult,
    Errors,
    Pick<VerifyOtpParams, 'phone' | 'otp'>
  >(queryKey, values =>
    VerifyOtp(values)
      .then(res => {
        return res;
      })
      .catch(err => {
        toast.error(err.message as string);
        return err;
      }),
  );

  if (mutation.isError && mutation.error) {
    toast.error(mutation.error.message);
  }

  return mutation;
}
