import { createTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material';
import React from 'react';
import GetThemePalette from './palette';
import ThemeTypography from './typography';
import GetThemeComponents from './components';

// Override properties of MUI

declare module '@mui/material/styles' {
  interface CustomPalette {
    greylight: PaletteColorOptions;
  }
  // interface Palette extends CustomPalette {}
  // interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xlarge: true;
    xxlarge: true;
    navlarge: true;
    navlargeactive: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    purplelight: true;
    greyselected: true;
    greylight: true;
    infolight: true;
    successlight: true;
    errorlight: true;
    warninglight: true;
  }
  interface ChipPropsSizeOverrides {
    singleLetter: true;
    xsmall: true;
    xsmallicon: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1x1: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    componentTooltip: React.CSSProperties;
    inputText: React.CSSProperties;
    inputLabel: React.CSSProperties;
    chip: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1x1?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonMedium: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    componentTooltip: React.CSSProperties;
    inputText: React.CSSProperties;
    inputLabel: React.CSSProperties;
    chip: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1x1: true;
    body3: true;
    body4: true;
    buttonLarge: true;
    buttonMedium: true;
    buttonSmall: true;
    componentTooltip: true;
    inputText: true;
    inputLabel: true;
    chip: true;
  }
}

const themePalette = GetThemePalette();
const themeComponent = GetThemeComponents(themePalette);
export const themeBreakpoints = {
  values: { xs: 0, sm: 375, md: 640, lg: 1024, xl: 1440 },
};

// Create a theme instance.
const theme = createTheme({
  palette: themePalette,
  typography: ThemeTypography,
  breakpoints: themeBreakpoints,
  components: themeComponent,
});

export default theme;
