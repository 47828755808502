const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      void getCLS(onPerfEntry);
      void getFID(onPerfEntry);
      void getFCP(onPerfEntry);
      void getLCP(onPerfEntry);
      void getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
