import { createTheme, PaletteMode, PaletteOptions } from '@mui/material';
import {
  deepPurple,
  grey,
  green,
  red,
  purple,
  lightBlue,
} from '@mui/material/colors';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

const GetThemePalette = () => {
  return {
    purplelight: createColor(deepPurple[50]),
    greyselected: createColor(grey[800]),
    greylight: createColor(grey[200]),
    infolight: createColor('#0288D112'),
    successlight: createColor(green[50]),
    errorlight: createColor(red[50]),
    warninglight: createColor('#FDF0E6'),
    common: {
      black: '#000',
      white: '#fff',
    },
    mode: 'light' as PaletteMode,
    primary: {
      light: '#64A0FF',
      // main: '#0572E6',
      main: '#f72a98',
      dark: '#0048B3',
    },
    secondary: {
      light: purple[300],
      main: red[500],
      dark: purple[700],
    },
    error: {
      light: red[400],
      main: red[700],
      dark: red[800],
    },
    warning: {
      light: '#FF9800',
      main: '#ED6C02',
      dark: '#E65100',
    },
    success: {
      light: green[500],
      main: green[800],
      dark: green[900],
    },
    info: {
      light: lightBlue[500],
      main: lightBlue[700],
      dark: lightBlue[900],
    },
    grey: {
      50: grey[50],
      100: grey[100],
      200: grey[200],
      300: grey[300],
      500: grey[500],
      600: grey[600],
      700: grey[700],
      800: grey[800],
      900: grey[900],
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      paper: '#fff',
      default: '#eee',
    },
  } as PaletteOptions;
};

export default GetThemePalette;
