export const BusinessSizes = [
  { label: 'Just Me', value: 'Just Me' },
  { label: '2-10', value: '2-10' },
  { label: '10-50', value: '10-50' },
  { label: '50-100', value: '50-100' },
  { label: '100-200', value: '100-200' },
  { label: '200-500', value: '200-500' },
  { label: '500-1000', value: '500-1000' },
  { label: 'More than 1000', value: 'More than 1000' },
];
