import { makePostRestRequest } from 'src/services/apiServices';
import { SignupParams, SignupResult } from '../Auth/types';

export async function Signup(params: SignupParams): Promise<SignupResult> {
  const path = `auth/signup`;
  return await makePostRestRequest<SignupParams, SignupResult>(
    path,
    params,
    'application/json',
  );
}
